























import Vue from 'vue';
import "../dialogs/ChangePassword.vue";
import {settings} from "@/settings";
import pdf from "vue-pdf";
import {getPageCountOfPdf} from "@/helpers/pdf";
import {User} from "mp-common/src/types/entities/user";

export default Vue.extend({
  name: 'CurrentPlan',
  components: {
    pdf
  },
  props: {
    user: {
      required: true,
    }
  },
  data() {
    return {
      miniPlan: "",
      pageCount: 0,
    }
  },
  created: async function () {
    await this.fetchMiniPlan();
  },
  methods: {
    async fetchMiniPlan() {
      const result = await fetch(settings.backendUrl + "/miniSchedule/getPersonalPlan", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.authToken,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({familyName: (this.user as User).lastName})
      });
      if (result.status === 200) {
        const blob = await result.blob();
        this.miniPlan = window.URL.createObjectURL(blob);
        this.pageCount = await getPageCountOfPdf(blob);
      }
    }
  }
})
